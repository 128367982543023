var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jf-pop"},[_c('div',{staticClass:"jf-pop-main"},[_c('div',{staticClass:"pop-banner"}),_c('div',{staticClass:"pop-from"},[_c('div',{staticClass:"pop-from-box"},[_c('div',{staticClass:"pop-title"},[_vm._v(" "+_vm._s(_vm.isLoginShow.type === "register" ? _vm.$t("components.Login.226102-0") : _vm.$t("components.Login.226102-1"))+" ")]),_c('div',{staticClass:"icon-close close",on:{"click":_vm.closePop}}),_c('div',{staticClass:"jf-from"},[_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from.account),expression:"from.account"}],staticClass:"jf-input",attrs:{"type":"text","placeholder":_vm.$t('components.Login.226102-2')},domProps:{"value":(_vm.from.account)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.from, "account", $event.target.value)}}})]),_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from.pwd),expression:"from.pwd"}],staticClass:"jf-input",attrs:{"type":"password","placeholder":_vm.$t('components.Login.226102-3')},domProps:{"value":(_vm.from.pwd)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.from, "pwd", $event.target.value)}}})]),(
              _vm.isLoginShow.type === 'register' &&
              _vm.registerItemList &&
              _vm.fromItemShow(7)
            )?_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from.realName),expression:"from.realName"}],staticClass:"jf-input",attrs:{"type":"text","placeholder":_vm.$t('userInfo.index.388995-1')},domProps:{"value":(_vm.from.realName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.from, "realName", $event.target.value)}}})]):_vm._e(),(
              _vm.isLoginShow.type === 'register' &&
              _vm.registerItemList &&
              _vm.fromItemShow(15)
            )?_c('div',{staticClass:"row"},[_c('el-select',{staticClass:"jf-select",attrs:{"placeholder":_vm.$t('components.Login.226102-4')},model:{value:(_vm.from.money),callback:function ($$v) {_vm.$set(_vm.from, "money", $$v)},expression:"from.money"}},_vm._l((_vm.moneyList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1):_vm._e(),(
              _vm.isLoginShow.type === 'register' &&
              _vm.registerItemList &&
              _vm.fromItemShow(1)
            )?_c('div',{staticClass:"row"},[_c('el-select',{staticClass:"jf-select",staticStyle:{"width":"2rem"},attrs:{"placeholder":_vm.$t('components.Login.226102-4')},model:{value:(_vm.from.area),callback:function ($$v) {_vm.$set(_vm.from, "area", $$v)},expression:"from.area"}},_vm._l((_vm.areaList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from.phone),expression:"from.phone"}],staticClass:"jf-input",attrs:{"type":"tel","placeholder":_vm.$t('components.Login.226102-5')},domProps:{"value":(_vm.from.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.from, "phone", $event.target.value)}}})],1):_vm._e(),(
              _vm.isLoginShow.type === 'register' &&
              _vm.registerItemList &&
              _vm.fromItemShow(12)
            )?_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from.phoneCode),expression:"from.phoneCode"}],staticClass:"jf-input",attrs:{"type":"text","placeholder":_vm.$t('components.Login.226102-7')},domProps:{"value":(_vm.from.phoneCode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.from, "phoneCode", $event.target.value)}}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.codeTimeing),expression:"codeTimeing"}],staticClass:"text-white",class:{ 'no-link': _vm.isSengSms },on:{"click":_vm.sengSms}},[_vm._v(" "+_vm._s(!_vm.isSengSms ? _vm.$t("components.Login.226102-8") : _vm.codeTime + _vm.$t("components.Login.226102-9"))+" ")])]):_vm._e(),(
              _vm.isLoginShow.type === 'register' &&
              _vm.registerItemList &&
              _vm.fromItemShow(18)
            )?_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from.invite),expression:"from.invite"}],staticClass:"jf-input",attrs:{"type":"text","placeholder":_vm.$t('components.Login.226102-10')},domProps:{"value":(_vm.from.invite)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.from, "invite", $event.target.value)}}})]):_vm._e(),(_vm.isLoginShow.type === 'register')?_c('div',{staticClass:"jf-from-label"},[_c('label',{staticClass:"jf-check icon-Selected"}),_vm._v(" "+_vm._s(_vm.$t("components.Login.226102-11"))),_c('span',{staticClass:"span",on:{"click":_vm.openLogin}},[_vm._v(_vm._s(_vm.$t("components.Login.226102-12")))]),_vm._v(_vm._s(_vm.$t("components.Login.226102-13"))+" ")]):_c('div',{staticClass:"jf-from-label",on:{"click":_vm.contactLink}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.Login.226102-14")))])]),_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn-green",on:{"click":_vm.submitCheck}},[_vm._v(" "+_vm._s(_vm.isLoginShow.type === "register" ? _vm.$t("components.Head.949315-2") : _vm.$t("components.Login.226102-1"))+" ")])]),(_vm.isLoginShow.type === 'login')?_c('div',{staticClass:"jf-login-tip",on:{"click":function($event){return _vm.tabFromType('register')}}},[_vm._v(" "+_vm._s(_vm.$t("components.Login.226102-15"))),_c('span',{staticClass:"a-link-green"},[_vm._v(_vm._s(_vm.$t("components.Login.226102-16")))])]):_c('div',{staticClass:"jf-login-tip",on:{"click":function($event){return _vm.tabFromType('login')}}},[_vm._v(" "+_vm._s(_vm.$t("components.Login.226102-17"))),_c('span',{staticClass:"a-link-green"},[_vm._v(_vm._s(_vm.$t("components.Login.226102-1")))])]),_vm._m(0)])]),(_vm.show)?_c('div',{staticClass:"jf-show"},[_c('Slider',{ref:"sliderComponent",attrs:{"fromValue":_vm.from,"log":true},on:{"sumit":_vm.validImg,"close":_vm.onClose}})],1):_vm._e()])]),_c('div',{attrs:{"id":"captcha-box"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jf-login-img"},[_c('img',{attrs:{"src":require("@/assets/pic/ad01.webp")}})])
}]

export { render, staticRenderFns }