<template>
  <div class="ebpay-box">
    <h2>{{ $t("components.three.584299-0") }}</h2>
    <div class="ebpay-time">{{ conutdown }}</div>
    <p>
      {{ $t("components.three.584299-1") }}<br />{{
        $t("components.three.584299-2")
      }}
      <span class="text-blue" @click="openContact(baseInfo[6].configValue)">{{
        $t("components.three.584299-3")
      }}</span>
    </p>
    <div class="ebpay-tips">
      <!-- <img src="@/assets/images/userinfo/tips.png" /> -->
      {{ $t("components.three.584299-4") }}
    </div>
    <div class="payWid">
      <button class="link-a" @click="canlceOrder">{{ $t('components.three.536638-0') }}</button>
      <button class="link-a" @click="viewOrder">{{ $t('components.three.536638-1') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      conutdown: "30:00",
      countdownTime: 1800,
      timerPost: null,
    };
  },
  mounted() {
    this.countTime();
    this.timeCheck();
  },
  methods: {
    viewOrder() {
      this.$store.dispatch("setDepositAc", false);
      this.$router.push("/userInfo/transaction");
    },
    canlceOrder() {
      this.$confirm(this.$t('components.three.536638-2'), this.$t('components.three.536638-3'), {
        confirmButtonText: this.$t('components.three.536638-4'),
        cancelButtonText: this.$t('components.three.536638-5'),
      })
        .then(() => {
          this.$Api.cancelRechargeOrder(this.info.orderNo + "").then((res) => {
            if (res) {
              this.$message({
                type: "success",
                message: this.$t('components.three.536638-6'),
              });
              clearInterval(this.timerPost);
              this.$store.dispatch("setDepositAc", false);
              this.$router.push("/userInfo/transaction");
            }
          });
        })
        .catch(() => {
          console.log(11);
        });
    },
    timeCheck() {
      this.timerPost = setInterval(() => {
        this.getPaymentOrderStatus();
      }, 6000);
    },
    getPaymentOrderStatus() {
      const prams = {
        rechargeNumber: this.info.orderNo,
      };
      this.$Api.getPaymentOrderStatus(prams).then((res) => {
        if (res) {
          let state = res.status;
          switch (state) {
            case 3:
            case -1:
              clearInterval(this.timerPost);
              this.$alert(this.$t('components.three.536638-7'), this.$t('components.three.536638-3'), {
                confirmButtonText: this.$t('components.three.536638-4'),
                callback: (action) => {
                  this.$router.push("/userInfo/transaction");
                  this.$store.dispatch("setDepositAc", false);
                },
              });
              break;
            case 1:
              clearInterval(this.timerPost);
              this.$message({
                type: "success",
                message: this.$t('components.three.536638-8'),
              });
              this.$router.push("/userInfo/transaction");
              this.$store.dispatch("setDepositAc", false);
              break;
          }
        }
      });
    },
    countTime() {
      this.timer = setTimeout(() => {
        this.countdownTime--;
        var minute = parseInt(this.countdownTime / 60);
        var second = parseInt(this.countdownTime % 60);
        this.conutdown = minute + ":" + (second < 10 ? "0" + second : second);
        if (this.countdownTime === 0) {
          clearTimeout(this.timer);
        }
        this.countTime();
      }, 1000);
    },
  },
  destroyed() {
    clearInterval(this.timerPost);
  },
};
</script>

<style lang="scss" scoped>
.ebpay-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px !important;
  text-align: center;

  h2 {
    font-weight: normal;
  }
}

.ebpay-time {
  width: 129px;
  height: 129px;
  background: url("@/assets/pic/time.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  font-size: 26px;
}

.ebpay-tips {
  display: flex;
  align-items: center;
  margin-top: 24px;

  img {
    width: 18px;
    margin-right: 4px;
  }
}
.text-blue {
  color: var(--text-green2);
  cursor: pointer;
}
.payWid {
  display: flex;
  gap: 20px;
  padding: 50px 0;
  .link-a {
    background: none;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 8px 30px;
    color: #999;
    font-size: 13px;
    cursor: pointer;
    &:hover {
      color: var(--theme);
      border: 1px solid var(--theme);
    }
  }
}
</style>
