<template>
  <section class="jf-wid">
    <!-- banner -->
    <div class="jf-wid jf-banner">
      <div class="jf-wid-14" v-if="slideList && slideList.length">
        <div class="prve icon-arrow-left" @click="tabBaner('prve')"></div>
        <div class="next icon-arrow-right" @click="tabBaner('next')"></div>
        <div class="banner-scroll">
          <ul
            :style="{
              transform: 'translateX(' + slideVal + 'px)',
              transition: scrollLeftStar,
            }"
          >
            <li
              v-for="(item, index) in slideList"
              :key="index"
              ref="bannerWid"
              @click="openBanner(item)"
            >
              <img v-lazy="item.imgUrl" />
            </li>
          </ul>
        </div>
        <div class="banner-nav">
          <span
            v-for="(item, index) in slideList.length - 4"
            :class="{ hover: navIndex === index }"
            :key="index"
          ></span>
        </div>
      </div>
    </div>
    <div class="jf-wid-14 flex-column">
      <!-- 公告 -->
      <div class="jf-msg" v-if="newsList && newsList.length">
        <div class="icon-broadcast icon"></div>
        <div class="jf-msg-scroll" ref="scorll">
          <ul ref="scorllWid" :style="scrollInfo">
            <!-- @mouseout="scrollStar" @mouseover="moveScroll" -->
            <li
              v-for="(item, index) in newsList"
              @click="openMessage(item)"
              :key="index"
              v-html="item.scrollContent"
            ></li>
          </ul>
        </div>
      </div>
      <!-- 搜索 -->
      <div class="sj-search" @click="isSearch = true">
        <span class="icon icon-search"></span>
        <div class="text">{{ $t("views.index.519341-0") }}</div>
      </div>
      <!-- 导航 -->
      <div class="sj-menu">
        <div
          v-for="(item, index) in menus"
          :key="index"
          class="item"
          :class="{ hover: index === 0 }"
          @click="jumpRouter(item)"
        >
          <span class="icon" :class="item.icon"></span>
          <span class="text">{{ item.name }}</span>
        </div>
      </div>
      <!-- 游戏 -->
      <div class="jf-game-flex" v-if="homeGameList.length">
        <div
          class="jf-game"
          v-for="(item, index) in homeGameList"
          :key="index"
          v-show="item.number > 0 && !['1'].includes(item.type)"
        >
          <div class="title" v-if="item.number > 0">
            <div class="h3">{{ item.name }}</div>
            <div class="right">
              <span class="all" @click="openUrl(item.link)"
                >{{ $t("views.index.519341-1") }} {{ item.number }}</span
              >
              <div class="arrow">
                <div
                  class="prve icon-arrow-left"
                  @click="tabGameIndex('prve', index)"
                ></div>
                <div
                  class="next icon-arrow-right"
                  @click="tabGameIndex('next', index)"
                ></div>
              </div>
            </div>
          </div>
          <div
            class="jf-game-list"
            v-if="item.list.length > 0"
            :class="'gameItem' + index"
          >
            <ul class="grid-column" :class="'gameScroll' + index">
              <li v-for="(row, rowIndex) in item.list" :key="rowIndex">
                <div class="icon" v-if="['0', '2'].includes(row.gameType)">
                  <img
                    :src="
                      require('@/assets/pic/type/' + row.platType + '.webp')
                    "
                    class="icon-img-type"
                  />
                </div>
                <span class="name">{{ row.gameName }}</span>
                <span class="link" @click="openGame(row)">{{
                  $t("views.index.519341-2")
                }}</span>
                <img v-lazy="row.gameImgUrl" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="fj-msg-pop" v-if="ismsgShow">
      <div class="fj-msg-pop-main">
        <div class="title">{{ $t("message.mailList.151926-10") }}</div>
        <ul class="message-scroll">
          <li
            class="message-row"
            v-for="(item, index) in newsList"
            :key="index"
          >
            <span class="title-link">{{ item.title }}</span>
            <p class="time">{{ item.createTime }}</p>
            <div class="content" v-html="item.content"></div>
          </li>
        </ul>

        <div class="main-save">
          <el-checkbox v-model="isViewMsg">{{
            $t("views.index.305771-0")
          }}</el-checkbox>
        </div>
        <div class="message-close icon-close" @click="messageClose"></div>
      </div>
    </div>
    <SerachGame v-if="isSearch" :visible.sync="isSearch" />
  </section>
</template>
<script>
import { mapState } from "vuex";
import SerachGame from "@/components/SearchGame.vue";
export default {
  components: {
    SerachGame,
  },
  data() {
    return {
      ismsgShow: false,
      isViewMsg: true,
      navIndex: 0,
      homeGameList: [],
      newsList: null,
      scorllLeftVal: 0,
      scrollLeftStar: "all .4s",
      timerText: null,
      scorllWid: 0,
      isScroll: false,
      slideList: null,
      slideVal: 0,
      bannerwid: 0,
      transformVal: "all .4s",
      isSearch: false,
      scrollInfo: null,
    };
  },
  computed: {
    ...mapState(["navsList", "userInfo", "payPwd", "langTypeVal", "langList"]),
    menus() {
      const arrs = [
        {
          name: this.$t("views.index.519341-3"),
          icon: "icon-home",
        },
        {
          name: this.$t("views.index.519341-4"),
          icon: "icon-gift",
          link: "/promotion",
        },
        {
          name: this.$t("views.index.519341-5"),
          icon: "icon-dice",
          link: "/download",
        },
      ];
      if (this.navsList) {
        arrs.splice(2, 0, ...this.navsList);
        return arrs;
      } else {
        return arrs;
      }
    },
    // 登录之后的
    testVal() {
      let val = {
        one: this.navsList,
        two: this.userInfo,
      };
      return val;
    },
    // 登录之前
    loginQ() {
      let isLogin = false;
      if (this.navsList && !this.isLogin) {
        isLogin = true;
      }
      return isLogin;
    },
  },
  watch: {
    // 未登录调的游戏列表
    loginQ(val) {
      if (val) {
        console.log(111);

        this.allGameList();
      }
    },
    // 登录调的游戏列表
    testVal(val) {
      if (val.one && val.two) {
        console.log(222);
        this.allGameList();
      }
    },
    userInfo(val) {
      if (val) {
        this.checkName();
      }
    },
    langTypeVal(now, old) {
      if (now !== old) {
        console.log(333);
        this.allGameList();
      }
    },
  },
  mounted() {
    this.homeGameListVal = [];
    this.getTop50MessageList();
    this.getSilde();
    this.$nextTick(() => {
      window.addEventListener("resize", this.getBannerWid);
    });
    this.checkName();
    this.allGameList();
  },
  methods: {
    // 检测是否设置姓名
    checkName() {
      if (this.userInfo) {
        const { realName, sex, mobile, birthDate } = this.userInfo;
        if (
          !realName ||
          ![0, 1].includes(sex) ||
          !birthDate ||
          !mobile ||
          !this.payPwd
        ) {
          this.$confirm(
            this.$t("index.text.07312320"),
            this.$t("components.cancelOrder.530861-0"),
            {
              confirmButtonText: this.$t("components.order.568383-58"),
              cancelButtonText: this.$t("feedback.detail.095266-5"),
              type: "none",
            }
          )
            .then(() => {
              this.$router.push("/userInfo/index");
            })
            .catch(() => {
              console.log("11");
            });
        }
      }
    },
    messageClose() {
      if (this.isViewMsg) {
        const targetTime = new Date();
        localStorage.setItem("messageAuto", targetTime.getTime());
      }
      this.ismsgShow = false;
    },
    openBanner(val) {
      if (val.activityId != null) {
        this.$router.push("/promotion/detail?type=" + val.activityId);
      }
    },
    getBannerWid() {
      if (!(this.slideList && this.slideList.length)) return;
      const dom = this.$refs.bannerWid[0];
      this.bannerwid = dom.clientWidth;
      this.slideVal = -((this.navIndex + 1) * this.bannerwid);
    },
    // 切换banner
    tabBaner(val) {
      if (val === "prve") {
        this.navIndex--;
        this.slideVal = -((this.navIndex + 1) * this.bannerwid);
        if (this.navIndex === -1) {
          this.navIndex = this.slideList.length - 5;
          setTimeout(() => {
            this.transformVal = "none";
            this.slideVal = -((this.navIndex + 1) * this.bannerwid);
            setTimeout(() => {
              this.transformVal = "all .4s";
            }, 100);
          }, 600);
          return;
        }
      } else {
        this.navIndex++;
        this.slideVal = -((this.navIndex + 1) * this.bannerwid);
        if (this.navIndex === this.slideList.length - 4) {
          this.navIndex = 0;
          setTimeout(() => {
            this.transformVal = "none";
            this.slideVal = -((this.navIndex + 1) * this.bannerwid);
            setTimeout(() => {
              this.transformVal = "all .4s";
            }, 100);
          }, 600);
          return;
        }
      }
    },
    // banner
    getSilde() {
      const params = {
        position: 1,
        device: 1,
      };
      this.$Api.getSilde(params).then((res) => {
        if (res) {
          let arr = res.slideList;
          let arrs = [];
          if (res.slideList.length >= 3) {
            arrs.push(arr[arr.length - 1]);
            arrs.push(...arr);
            arrs.push(arr[0]);
            arrs.push(arr[1]);
            arrs.push(arr[2]);
          }
          this.slideList = arrs;
          this.$nextTick(() => {
            this.getBannerWid();
          });
        }
      });
    },
    tabGameIndex(type, val) {
      let domOver = document.getElementsByClassName("gameItem" + val)[0]
        .clientWidth;
      let dom = document.getElementsByClassName("gameScroll" + val)[0];
      if (dom <= 0) return;
      if (type === "next") {
        dom.scrollLeft += domOver;
      } else {
        dom.scrollLeft -= domOver;
      }
    },
    // 打开游戏
    openGame(val) {
      if (!this.isLogin) {
        // this.$confirm("请先登录账号，才能了解更多", "温馨提示", {
        //   confirmButtonText: "确定",
        //   showCancelButton: false,
        //   customClass: "el-pop-wid",
        // })
        //   .then(() => {
        //   })
        //   .catch(() => {});
        this.openLoginPop();
        return;
      }
      this.$loading.show();
      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          this.$loading.hide();
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
        // this.gameUrl = url.url;
      });
    },
    // 打开公告
    openMessage(val) {
      this.$confirm(val.content, val.title, {
        confirmButtonText: this.$t("views.index.519341-6"),
        customClass: "el-pop-wid-message",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (!this.isLogin) return;
          this.$router.push("/userInfo/message");
        })
        .catch(() => {
          console.log(11);
        });
    },
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList;
          if (this.newsList && this.newsList.length) {
            // 设定24小时后重新开启公告通知弹窗
            const isDay = localStorage.getItem("messageAuto");
            if (!isDay && this.newsList.length) {
              this.ismsgShow = true;
            }
            if (isDay) {
              const targetDay = 24 * 60 * 60 * 1000; //一天秒数
              const isDYDay = new Date().getTime() - isDay; //获取当前日期是否超过一天
              if (isDYDay > targetDay) {
                localStorage.removeItem("messageAuto");
              }
            }
          }
        }
      });
    },
    // 跑马灯初始化
    scrollInit() {
      // 获取box宽度
      const dowSocrll = this.$refs.scorll.clientWidth;
      this.scorllWid = this.$refs.scorllWid.clientWidth;
      this.isScroll = this.scorllWid > dowSocrll ? true : false;
      if (this.isScroll) {
        this.newsList.push(...this.newsList);
        let speed = Math.round(this.scorllWid / 24);

        let style = document.createElement("style");
        console.log(style);

        style.type = "text/css";
        let keyFrames =
          "\
    @-webkit-keyframes rowup {\
        0% {\
            -webkit-transform: translate3d(0, 0, 0);\
            transform: translate3d(0, 0, 0);\
        }\
        100% {\
            -webkit-transform: translate3d(A_DYNAMIC_VALUE, 0, 0);\
            transform: translate3d(A_DYNAMIC_VALUE, 0, 0);\
        }\
    }\
    @keyframes rowup {\
        0% {\
            -webkit-transform: translate3d(0, 0, 0);\
            transform: translate3d(0, 0, 0);\
        }\
        100% {\
            -webkit-transform: translate3d(A_DYNAMIC_VALUE, 0, 0);\
            transform: translate3d(A_DYNAMIC_VALUE, 0, 0);\
        }\
    }";
        style.innerHTML = keyFrames.replace(
          /A_DYNAMIC_VALUE/g,
          -this.scorllWid + "px"
        );
        this.scrollInfo = {
          "animation-duration": speed + "s",
        };
        console.log(this.scrollInfo);

        document.getElementsByTagName("head")[0].appendChild(style);
        // this.scrollStar();
      }
    },
    scrollStar() {
      clearInterval(this.timerText);
      this.timerText = setInterval(() => {
        const valNumber = 5;

        this.scorllLeftVal -= valNumber;
        this.scrollInfo = {
          transform: "translateX(" + this.scorllLeftVal + "px)",
          transition: 'all .4s"',
        };
        if (this.scorllLeftVal <= -this.scorllWid) {
          this.scorllLeftVal = 0;
          this.scrollLeftStar = "none";
          setTimeout(() => {
            this.scrollLeftStar = "all .4s";
          }, 50);
        }
      });
    },
    // 页面跳转
    jumpRouter(val) {
      if (val.link) {
        this.$router.push(val.link);
      }
    },
    // 首页所有栏目游戏
    async allGameList() {
      if (!this.navsList) return;
      for (let i = 0; i < this.navsList.length; i++) {
        const last = this.navsList.length - 1 === i ? true : false;
        await this.getGameListByGameType(this.navsList[i], last);
      }
      this.homeGameList = Object.freeze(this.homeGameListVal);
      this.scrollInit();
    },
    async getGameListByGameType(val, slot) {
      const initLang = this.langList.find(
        (item) => item.type === this.$i18n.locale
      );
      const initLangVal = initLang ? initLang.value : "";
      this.$loading.show();
      const params = {
        gameName: "",
        gameType: val.gameType,
        pageIndex: 1,
        pageSize: 14,
        platType: "",
        languageType: this.userInfo
          ? this.userInfo.languageType
          : localStorage.getItem("langParam") || initLangVal,
      };
      const res = await this.$Api.getGameListByGameType(params);
      if (res) {
        this.$loading.hide();
        let obj = {
          id: val.id,
          type: val.gameType,
          name: val.name,
          number: res.page.total,
          list: res.page.records,
          link: "/game?type=" + val.gameType,
        };
        this.homeGameListVal.push(obj);
        this.homeGameListVal.sort((a, b) => a.id - b.id);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getBannerWid);
    clearInterval(this.timerText);
  },
};
</script>
<style lang="scss" scoped>
.jf-banner {
  background: var(--bg-3);
  padding: 0.16rem 0 0;
  &-wid {
    width: 100%;
  }
  .jf-wid-14 {
    display: flex;
    flex-direction: column;
    position: relative;
    .prve,
    .next {
      position: absolute;
      top: 50%;
      z-index: 2;
      transform: translateY(-60%);
      color: var(--text-1);
      width: 0.4rem;
      height: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--bg-1);
      cursor: pointer;
    }
    .prve {
      left: -0.24rem;
    }
    .next {
      right: -0.24rem;
    }
  }
}
.banner-scroll {
  overflow: hidden;
  width: 100%;
  ul {
    height: 100%;
    display: flex;
    transition: all 0.4s;
    max-height: 227px;
    li {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 3);
      flex: none;
      height: 100%;
      padding: 0 0.1rem;
      position: relative;
      &::after {
        contain: "";
        display: block;
        padding-top: 51%;
      }
      img {
        width: 100%;
      }
    }
  }
}
.banner-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.1rem 0;
  span {
    width: 0.08rem;
    height: 0.08rem;
    background: #979797;
    border-radius: 0.08rem;
    margin: 0 4px;
    opacity: 0.2;
    &.hover {
      width: 0.22rem;
      background: white;
      opacity: 1;
    }
  }
}
.jf-msg {
  color: var(--text-green1);
  display: flex;
  align-items: center;
  gap: 0.06rem;
  margin-top: 0.16rem;
  width: 100%;
  .icon {
    font-size: 0.14rem;
  }
  &-scroll {
    flex: 1;
    overflow: hidden;
    ul {
      display: inline-block;
      white-space: nowrap;
      animation: 10s rowup linear infinite normal;
      li {
        cursor: pointer;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.3rem;
      }
    }
  }
}
// @keyframes rowup {
//   0% {
//     transform: translate3d(0, 0, 0);
//   }
//   100% {
//     transform: translate3d(-307px, 0, 0);
//   }
// }
.sj-search {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  background: var(--bg-3);
  border-radius: 0.04rem;
  padding: 0 0.2rem;
  width: 100%;
  height: 0.48rem;
  margin-top: 0.16rem;
  color: var(--text-pl);
  .text {
    font-size: 0.16rem;
  }
}
.sj-menu {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.15rem;
  margin-top: 0.2rem;
  overflow: hidden;
  .item {
    color: var(--text-1);
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 0.06rem;
    height: 0.4rem;
    padding: 0 0.2rem;
    font-size: 0.14rem;
    cursor: pointer;
    white-space: normal;
    &.hover {
      background: var(--bg-hover);
      color: var(--text);
    }
    .text {
      white-space: nowrap;
    }
  }
}
.jf-game {
  width: 100%;
  &-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.2rem;
    margin-top: 0.24rem;
    padding-bottom: 0.4rem;
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h3 {
      font-size: 0.16rem;
      color: var(--text);
    }
    .right {
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }
    .all {
      background: var(--bg-3);
      padding: 0 0.1rem;
      height: 0.28rem;
      color: var(--text);
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 0.02rem;
    }
    .arrow {
      display: flex;
      gap: 0.06rem;
      .prve,
      .next {
        height: 0.28rem;
        width: 0.28rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.1rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.02rem;
        cursor: pointer;
      }
    }
  }
}
.jf-game-list {
  width: 100%;
  overflow: hidden;
  margin-top: 0.1rem;
  ul {
    display: grid;
    grid-template-rows: repeat(var(--grid-rows), 1fr);
    grid-auto-flow: column;
    grid-auto-columns: calc(
      100% / var(--grid-num) - (var(--grid-num) - var(--grid-rows)) * 0.15rem /
        var(--grid-num)
    );
    position: relative;
    scroll-snap-type: x mandatory;
    -webkit-scroll-behavior: smooth;
    scroll-behavior: smooth;
    overflow: auto;
    overflow-y: hidden;
    row-gap: 0;
    gap: 0.15rem;
    li {
      flex: none;
      position: relative;
      border-radius: 0.04rem;
      overflow: hidden;
      &:hover {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: 2;
        }
        .link {
          display: flex;
        }
      }
      .name {
        position: absolute;
        bottom: 6px;
        left: 6px;
        right: 6px;
        border-radius: 10px;
        font-size: 12px;
        color: white;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 4px 0;
      }
      .icon {
        position: absolute;
        z-index: 1;
        width: 0.26rem;
        height: 0.26rem;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0.04rem;
        left: 0.04rem;
        background: white;
        border-radius: 0.06rem;
        overflow: hidden;
        box-shadow: 0 0.04rem 0.06rem rgba(0, 0, 0, 0.2);

        &-img-type {
          width: 100%;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .link {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #009d81;
        z-index: 2;
        height: 0.36rem;
        border-radius: 0.04rem;
        padding: 0 0.1rem;
        color: var(--text);
        display: none;
        align-items: center;
        justify-content: center;
        width: 60%;
        cursor: pointer;
        z-index: 2;
      }
    }
  }
}
.grid-column {
  --grid-num: 8;
  --grid-rows: 1;
}
@media screen and (max-width: 1430px) {
  .grid-column {
    --grid-num: 7;
    --grid-rows: 1;
  }
}
.fj-msg-pop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &-main {
    background: white;
    width: 600px;
    border-radius: 10px;
    position: relative;
    .title {
      font-size: 20px;
      text-align: center;
      padding: 20px 0;
      color: #000;
    }
  }
}
.message-scroll {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px 20px;
  flex-direction: column;
  display: flex;
  gap: 16px;
}
.message-row {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .title-link {
    font-size: 14px;
    color: #333;
  }
  .time {
    font-size: 12px;
    color: #666;
  }
  .content {
    color: #999;
    font-size: 16px;
  }
}
.message-close {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme1);
  color: white;
}
.main-save {
  background: #e3e3e3;
  padding: 10px;
  text-align: right;
  margin-top: 20px;
  border-radius: 0 0 10px 10px;
}
/deep/.el-checkbox__inner {
  background: #222;
  border: 1px solid rgba(255, 255, 255, 0.3);
  &::after {
    border: 1px solid #555;
    border-left: 0;
    border-top: 0;
    height: 7px;
  }
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: white;
  border: 1px solid white;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999;
}
</style>
