<template>
  <div class="jf-dep-pop">
    <div class="jf-dep-main">
      <div class="jf-dep-title">
        <div class="left">{{ $t("transfer.index.967102-0") }}</div>
        <div class="right icon-close" @click="close"></div>
      </div>
      <div class="jf-dep-content">
        <div class="user-box">
          <div class="user-box-title">{{ $t("transfer.index.967102-1") }}</div>
          <div class="transfer-list">
            <ul v-if="accountMoney">
              <li>
                <div class="row">
                  <span class="left">{{ $t("transfer.index.967102-2") }}</span>
                  <span class="right" @click="getOneTouchTransfer">
                    {{ $t("transfer.index.967102-3") }}
                  </span>
                </div>
                <div class="money text-ak">
                  {{ accountMoney.userBalance.toFixed(2) }}
                </div>
              </li>
              <li>
                <div class="row">
                  <span class="left">{{ $t("transfer.index.967102-4") }}</span>
                </div>
                <div class="money text-ak">
                  {{ accountMoney.frozenBalance.toFixed(2) }}
                </div>
              </li>
              <li>
                <div class="row">
                  <span class="left">{{ $t("transfer.index.967102-5") }}</span>
                </div>
                <div class="money text-ak">
                  {{ accountMoney.welfareCenterBalance.toFixed(2) }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <template v-if="userInfo">
          <div class="user-box">
            <div class="transfer-title">
              <div class="left">{{ $t("transfer.index.967102-6") }}</div>
              <div class="right">
                <span>{{ $t("transfer.index.967102-7") }}</span>
                <el-switch :value="isAuto" @change="openTips"> </el-switch>
              </div>
            </div>
            <div class="user-pay-name" v-if="!isAuto">
              <div class="transfer-auto">
                <div class="transfer-auto-row pay-input">
                  <span class="out">{{ $t("transfer.index.967102-8") }}</span>
                  <el-select
                    v-model="transferInfo.out"
                    :placeholder="$t('transfer.index.967102-9')"
                    class="main"
                    v-if="platTypeList"
                    @change="tabTransferNameOut"
                  >
                    <el-option
                      v-for="item in platTypeList"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                  <span class="arrow"></span>
                </div>
                <div
                  class="transfer-auto-img icon-round-transfer"
                  @click="tabTransfer"
                ></div>
                <div class="transfer-auto-row pay-input">
                  <span class="in">{{ $t("transfer.index.967102-10") }}</span>
                  <el-select
                    v-model="transferInfo.In"
                    :placeholder="$t('transfer.index.967102-10')"
                    class="main"
                    v-if="platTypeList"
                    @change="tabTransferNameIn"
                  >
                    <el-option
                      v-for="item in platTypeList"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                  <span class="arrow"></span>
                </div>
              </div>
              <p class="pay-gray-tips">{{ $t("transfer.index.967102-11") }}</p>
            </div>
          </div>
          <div class="user-box" v-if="!isAuto">
            <div class="user-box-title">
              {{ $t("transfer.index.967102-12") }}
            </div>
            <div class="transfer-input-money">
              <input
                type="text"
                v-model="transferInfo.money"
                :placeholder="$t('transfer.index.967102-13')"
                oninput="value = value.replace(/[^0-9.]/g,'').replace( /(\.\d{2})\d*/, '$1')"
                class="pay-input"
              />
              <span class="unit">RMB</span>
              <span class="all-in" @click="allMoney">{{
                $t("transfer.index.967102-14")
              }}</span>
            </div>
            <div class="transfer-link">
              <el-button
                class="wid100"
                type="primary"
                :disabled="!transferInfo.money"
                @click="convertMoney(transferInfo.money)"
                >{{ $t("transfer.index.967102-15") }}</el-button
              >
            </div>
          </div>
          <div class="user-box">
            <div class="transfer-title">
              <div class="left">{{ $t("transfer.index.967102-16") }}</div>
              <div class="right">
                <span>{{ $t("transfer.index.967102-17") }}</span>
                <el-switch v-model="isMoney"> </el-switch>
              </div>
            </div>

            <div
              class="transfer-money-list"
              v-if="!isMoney && accountMoney && platTypes"
            >
              <ul>
                <li v-for="(item, index) in platTypes" :key="index">
                  <span class="name">{{ item.name }}</span>
                  <p
                    class="money text-ak"
                    :class="{ 'text-black': item.money > 0 }"
                  >
                    ¥{{ item.money }}
                  </p>
                  <span
                    class="link icon-share3"
                    v-if="accountMoney.userBalance > 0"
                    @click="
                      convertMoney(Number(accountMoney.userBalance), item.type)
                    "
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isMoney: false,
      isAuto: false,
      transferInfo: {
        out: "",
        In: "ag",
        mode: false, //false转入，true转出
        money: undefined,
      },
      platTypes: null,
      platTypeList: null,
    };
  },
  computed: {
    ...mapState(["accountMoney", "userInfo"]),
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.initOff();
      }
    },
  },
  mounted() {
    this.getAllPlatTypes();
  },
  methods: {
    close() {
      this.$store.dispatch("setTransferAc", false);
    },
    // 一键回收
    getOneTouchTransfer() {
      this.$Api.getOneTouchTransfer().then((res) => {
        this.getAllPlatTypes();
        // 金额接口
        this.$Api.getUserBalance().then((res) => {
          if (res) {
            this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
          }
        });
      });
    },
    // 初始化开关
    initOff() {
      if (this.userInfo.walletType === 1) {
        this.isAuto = false;
      } else {
        this.isAuto = true;
      }
    },
    changeWalletType() {
      this.$Api.changeWalletType().then((res) => {
        if (res) {
          if (this.isAuto) {
            this.$message({
              message: this.$t("transfer.index.967102-18"),
              type: "success",
            });
          } else {
            this.$message({
              message: this.$t("transfer.index.967102-19"),
              type: "success",
            });
          }
          // 个人资料
          this.$Api.getUserInfo().then((res) => {
            if (res) {
              this.$store.dispatch("setUserInfoAc", res.userInfo);
              this.initOff();
            }
          });
          // 金额接口
          this.$Api.getUserBalance().then((res) => {
            if (res) {
              this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
            }
          });
        }
      });
    },
    // 提交转账
    convertMoney(val, plat) {
      const { out, In, mode } = this.transferInfo;
      let params = {
        type: mode ? "out" : "in",
        allTrans: "",
        transAmount: val,
        platType: plat || out || In,
      };
      this.$Api.getConvertMoney(params).then((res) => {
        if (res) {
          // this.getAllPlatTypes();
          // 金额接口
          this.$Api.getUserBalance().then((res) => {
            if (res) {
              this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
            }
          });
          this.$message({
            type: "success",
            message: this.$t("transfer.index.967102-20"),
          });
          this.$router.push("/userInfo/transaction?type=transfer");
        }
      });
    },
    // 全部金额
    allMoney() {
      if (this.transferInfo.In) {
        this.transferInfo.money = this.accountMoney.userBalance;
      } else {
        let val = this.platTypes.find(
          (item) => item.type === this.transferInfo.out
        ).money;
        this.transferInfo.money = val;
      }
    },
    // 选择是否同名
    tabTransferNameOut(val) {
      if (val === "" && !this.transferInfo.In) {
        this.transferInfo.In = "ag";
        return;
      }
      if (this.transferInfo.out === val) {
        this.transferInfo.In = "";
        this.transferInfo.mode = true;
      }
    },
    // 选择是否同名
    tabTransferNameIn(val) {
      if (val === "" && !this.transferInfo.out) {
        this.transferInfo.out = "ag";
        return;
      }
      if (this.transferInfo.In === val) {
        this.transferInfo.out = "";
        this.transferInfo.mode = false;
      }
    },
    // 切换转入转出方式
    tabTransfer() {
      const { out, In } = this.transferInfo;
      this.transferInfo.out = In;
      this.transferInfo.In = out;
      this.transferInfo.mode = !this.transferInfo.mode;
    },
    // 游戏平台存在钱的接口
    getUserPlatBalance() {
      this.$Api.getUserPlatBalance().then((res) => {
        if (res && res.data) {
          this.platTypes.forEach((item) => {
            Object.keys(res.data).map((key) => {
              if (item.type === key) {
                item.money = res.data[key];
              }
            });
          });
        }
      });
    },
    // 平台
    getAllPlatTypes() {
      let list = [{ name: this.$t("transfer.index.967102-2"), type: "" }];
      this.$Api.getAllPlatTypes({ device: 1 }).then((res) => {
        const arr = [];
        Object.keys(res.platTypes).map((key) => {
          arr.push({
            name: res.platTypes[key].apiTitleOther,
            type: res.platTypes[key].platType,
            money: "0.00",
          });
        });
        this.platTypes = arr;
        list.push(...arr);
        this.platTypeList = list;
        if (this.platTypes) {
          this.getUserPlatBalance();
        }
        // this.transferInfo.In = this.platTypeList[1].type
      });
    },
    openTips() {
      this.changeWalletType();
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-list {
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.1rem;
  }

  li {
    position: relative;
    height: 100%;
    padding: 10px 12px;
    background: #17181b;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid transparent;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 24px;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--text-green2);
        border-radius: 20px;
        padding: 0 0.1rem;
        height: 24px;
        cursor: pointer;
        color: white;
        font-size: 12px;
      }
    }

    .money {
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      display: flex;
      align-items: center;
    }
  }
}

.pay-input {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #17181b;
  padding: 0 0.14rem;
  outline: none;
  color: white;
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--text-1);
    font-size: 14px;
  }
}

.pay-gray-tips,
.pay-red-tips {
  min-height: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0.1rem;
}

.pay-red-tips {
  color: #e95b5b;
  margin-top: 12px;
}

.user-pay-money {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: -12px;

  li {
    margin-bottom: 12px;
    margin-right: 8px;
    width: 110px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #f4faff;
    background-color: #f4faff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #30383f;
    position: relative;
    font-family: Akrobat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.transfer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC-Medium;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  .left {
    color: white;
  }

  .right {
    display: flex;
    align-items: center;
    font-weight: normal;
    color: #788b9d;

    span {
      margin-right: 4px;
      font-size: 14px;
    }
  }
}

.transfer-money-list {
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.1rem;
    align-items: center;
    flex-flow: wrap;

    li {
      border-radius: 4px;
      padding: 14px;
      position: relative;
      background: #17181b;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid white;
      }

      .name {
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }

      .money {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-left: 2px;
      }

      .link {
        position: absolute;
        right: 12px;
        top: 14px;
        width: 24px;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.6);
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.12rem;

        &:hover {
          width: 60px;
          height: 24px;
          border-radius: 20px;
          background: var(--text-green2);
          transition: all 0.3s;
          outline: none;
          border: none;
          text-align: center;

          &::before {
            content: "一键转入";
            color: white;
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.transfer-auto {
  display: flex;
  align-items: center;

  &-row {
    flex: 1;
    padding: 0 11px;
    display: flex;
    align-items: center;

    .out,
    .in {
      display: inline-block;
      width: 32px;
      height: 20px;
      line-height: 20px;
      background: rgba(37, 153, 248, 0.1);
      border-radius: 2px;
      text-align: center;
      color: #2599f8;
      font-size: 12px;
      margin-right: 8px;
    }

    .in {
      color: #77c48f;
      background: rgba(119, 196, 143, 0.1);
    }

    .main {
      flex: 1;
    }

    .arrow {
      width: 24px;
      height: 24px;
      display: block;
      //   background: url("@/assets/images/mine/icon62.png") no-repeat center center;
      background-size: 75%;
    }
  }

  &-img {
    margin: 0 14px;
    cursor: pointer;
    font-size: 0.22rem;

    &:hover {
      color: var(--text-green2);
    }
  }
}

.transfer-input-money {
  position: relative;
  display: inline-block;
  width: 100%;

  .all-in {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 24px;
    color: white;
    cursor: pointer;
  }

  .unit {
    position: absolute;
    right: 56px;
    top: 50%;
    padding-right: 12px;
    transform: translateY(-50%);

    &::before {
      position: absolute;
      content: "";
      right: 0;
      top: 50%;
      width: 1px;
      height: 14px;
      background: #dcdcdc;
      transform: translateY(-50%);
    }
  }
}

/deep/.el-select .el-input__inner {
  background: transparent;
  border: none;
  padding: 0;
}

/deep/.el-input__suffix {
  display: none;
}

.text-black {
  color: white !important;
}
.jf-dep-pop {
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jf-dep-main {
  margin: 0 auto;
  max-width: 8.4rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #24262b;
}
.jf-dep-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0.44rem;
  padding: 0.1rem 0.14rem;
  background: #17191b;
  .left {
    font-size: 0.16rem;
    color: white;
  }
  .right {
    cursor: pointer;
  }
}
.jf-dep-content {
  flex: 1;
  overflow-y: auto;
  padding: 0.2rem;
  max-height: 8.2rem;
  width: 5.8rem;
  position: relative;
  flex-direction: column;
  gap: 0.2rem;
  display: flex;
}
.user-box-title {
  justify-content: space-between;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sec {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: var(--text-1);
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 2px;
    }
  }
}
.user-box {
  display: flex;
  flex-direction: column;
  gap: 0.12rem;
}
.transfer-link {
  width: 100%;
  margin-top: 0.2rem;
  .wid100 {
    width: 100%;
  }
}
/deep/.transfer-auto-row {
  .el-select .el-input__inner {
    border: 1px solid transparent !important;
  }
  .el-input__inner:hover,
  .el-input__inner:focus {
    border-color: transparent !important;
  }
}
/deep/.el-switch__core {
  background: #17181b;
  border-color: #17181b;
}
</style>
