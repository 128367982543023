<template>
  <div id="app" class="layout">
    <Left class="layout-left" :key="langTypeVal" />
    <section class="layout-right">
      <Head />
      <div class="layout-hei" ref="appscroll">
        <router-view ref="viewbox" />
      </div>
      <Footer :key="langTypeVal" />
    </section>
    <Deposit v-if="isDeposit" />
    <Transfer v-if="isTransfer" />
    <withDrawal v-if="isWithdrawal" />
    <Login v-if="isLoginShow.show" />
  </div>
</template>
<script>
import Left from "@/components/Left.vue";
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Login from "@/components/Login.vue";
import Deposit from "@/views/deposit/index.vue";
import Transfer from "@/views/transfer/index.vue";
import withDrawal from "@/views/withdrawal/index.vue";
import { mapState } from "vuex";
export default {
  components: {
    Left,
    Head,
    Footer,
    Login,
    Deposit,
    Transfer,
    withDrawal,
  },
  computed: {
    ...mapState([
      "isLoginShow",
      "isLogin",
      "isDeposit",
      "isTransfer",
      "isWithdrawal",
      "langList",
      "langTypeVal",
    ]),
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.getBaseInfo();
        this.checkIsSetWidthPassword();
      }
    },
    langTypeVal(now, old) {
      console.log(now);
      const lang = this.langList.find((item) => item.value === now);
      if (old !== now) {
        this.$i18n.locale = lang.type;
        console.log(this.$i18n.locale);
      }
    },
  },
  mounted() {
    this.getWebsiteBasicInfo();
    this.getApiGameCategoryList();
    this.getBaseInfo();
    this.checkIsSetWidthPassword();
  },
  methods: {
    // 是否设置支付密码
    checkIsSetWidthPassword() {
      if (!this.isLogin) return;
      this.$Api.checkIsSetWidthPassword().then((res) => {
        if (res) {
          const val = res.msg === "yes" ? true : false;
          this.$store.dispatch("setPayPwdAc", val);
        }
      });
    },
    // 个人账户金额
    getBaseInfo() {
      const isLogin = localStorage.getItem("AppToken");
      if (!isLogin) {
        localStorage.removeItem("AppToken");
        localStorage.removeItem("userInfo");
        return;
      }
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
          const lang = localStorage.getItem("lang"); //
          const isLang = this.langList.find(
            (item) => item.value === res.userInfo.languageType
          );
          if (isLang) {
            // 设置语言包
            let params = {
              languageType: res.userInfo.languageType,
            };
            this.$i18n.locale = isLang.type;
            localStorage.setItem("lang", isLang.type); // 记录语言关键值
            this.$Api.changeLanguageAndCurrenc(params).then((res) => {
              if (res) {
                if (localStorage.getItem("loginState")) {
                  localStorage.removeItem("loginState");
                  location.reload();
                }
              }
            }); //告知后端语言
          }
        }
      });
    },
    // 个人账号信息
    // 基础信息
    getWebsiteBasicInfo() {
      this.$Api.getWebsiteBasicInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setBaseInfoAC", res.websiteBasicList);
          this.setTitle(res.websiteBasicList);
          this.exitIco(res.websiteBasicList);
        }
      });
    },
    setTitle(val) {
      document.title = val.find(
        (item) => item.configKey === "title"
      ).configValue;
    },
    exitIco(val) {
      let ico = document.querySelector('link[rel="icon"]');
      const title = document.querySelector("title");
      title.innerHTML = val[0].configValue;
      if (ico !== null) {
        ico.href = val[1].configValue;
      } else {
        ico = document.createElement("link");
        ico.rel = "icon";
        ico.href = val[1].configValue;
        document.head.appendChild(ico);
      }
    },
    // 游戏导航
    getApiGameCategoryList() {
      this.$loading.show();
      this.$Api.getApiGameCategoryList().then((res) => {
        this.$loading.hide();
        if (res) {
          const icons = [
            {
              type: "0",
              name: this.$t("src.App.536851-0"),
              icon: "icon-hot",
              link: "/game?type=0",
            },
            {
              type: "1",
              name: this.$t("src.App.536851-1"),
              icon: "icon-shixun",
              link: "/game?type=1",
            },
            {
              type: "2",
              name: this.$t("src.App.536851-2"),
              icon: "icon-slots",
              link: "/game?type=2",
            },
            {
              type: "3",
              name: this.$t("src.App.536851-3"),
              icon: "icon-caipiaodating",
              link: "/game?type=3",
            },
            {
              type: "4",
              name: this.$t("src.App.536851-4"),
              icon: "icon-basketball",
              link: "/game?type=4",
            },
            {
              type: "5",
              name: this.$t("src.App.536851-5"),
              icon: "icon-Game-Pad",
              link: "/game?type=5",
            },
            {
              type: "6",
              name: this.$t("src.App.536851-6"),
              icon: "icon-yu1",
              link: "/game?type=6",
            },
            {
              type: "7",
              name: this.$t("src.App.536851-7"),
              icon: "icon-poker",
              link: "/game?type=7",
            },
          ];
          res.apiGameCategoryList.forEach((item) => {
            icons.map((row) => {
              if (row.type === item.gameType) {
                item.icon = row.icon;
                item.link = row.link;
                item.name = row.name;
              }
            });
          });
          this.$store.dispatch("setNavsListAc", res.apiGameCategoryList);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100vh;
  display: flex;
  &-left {
    width: 2.5rem;
    background: var(--bg);
    display: flex;
    flex-direction: column;
  }
  &-right {
    flex: 1;
    background: var(--bg-3);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
}
.layout-hei {
  // min-height: calc(100vh - 460px);
  background: var(--bg-1);
}
</style>
