import Vue from "vue";
import Vuex from "vuex";

import { getToken } from "@/utils/token";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLogin: !!getToken() || false, //是否登陆
        userInfo: localStorage.getItem("userInfo") || null, //个人资料
        navsList: null, //导航栏
        navsVal: false, //是否加载完成-导航栏
        message: 0, //未读消息数量
        accountMoney: null, //账户钱
        baseInfo: null, //基础信息
        sms: false,
        isBankLength: false,
        languages: [
            { type: 'zh', id: '1' },
            { type: 'hk', id: '2' },
            { type: 'en', id: '3' }
        ],
        regAuth: null,
        hotsGameList: null,
        registerInfo: null,
        isLoginShow: {
            show: false,
            type: 'login'
        }, //登录弹层
        isShrink: false, //菜单收缩
        isDeposit: false, //是否开启存款
        isTransfer: false, //是否开启转账
        isWithdrawal: false, //是否开启取款
        payPwd: false, //是否设置取款密码
        smsSwitch: false,
        langList: [{
                name: "简体中文",
                value: "1",
                type: "zh",
            },
            {
                name: "Português",
                value: "6",
                type: "pg",
            },
            {
                name: "မြန်မာ",
                value: "8",
                type: "md",
            },
            {
                name: 'ไทย',
                value: '4',
                type: "th",
            },
            {
                name: 'English',
                value: '3',
                type: 'en'
            },
            {
                name: '繁体中文',
                value: '2',
                type: 'hk'
            },
            {
                name: 'Indonesian',
                value: '7',
                type: 'bi'
            },
        ],
        langTypeVal: null
    },
    getters: {},
    mutations: {
        setPayPwd(state, val) {
            state.payPwd = val;
        },
        setLangTypeVal(state, val) {
            state.langTypeVal = val;
        },
        setSmsSwitch(state, val) {
            state.smsSwitch = val;
        },
        setTransfer(state, val) {
            state.isTransfer = val;
        },
        setWithdrawal(state, val) {
            state.isWithdrawal = val;
        },
        setDeposit(state, val) {
            state.isDeposit = val;
        },
        setIsLoginShow(state, val) {
            state.isLoginShow = val;
        },
        setShrink(state, val) {
            state.isShrink = val;
        },
        setHasLogin(state, val) {
            state.isLogin = val;
        },
        setUserInfo(state, val) {
            state.userInfo = val;
        },
        setNavsList(state, val) {
            state.navsList = val;
        },
        setNavsVal(state, val) {
            state.navsVal = val;
        },
        setMessage(state, val) {
            state.message = val;
        },
        setAccountMoney(state, val) {
            state.accountMoney = val;
        },
        setBaseInfo(state, val) {
            state.baseInfo = val;
        },
        setSms(state, val) {
            state.sms = val;
        },
        setIsBankLength(state, val) {
            state.isBankLength = val;
        },
        setRegAuth(state, val) {
            state.regAuth = val;
        },
        setHotsGameList(state, val) {
            state.hotsGameList = val;
        },
        setRegisterInfo(state, val) {
            state.registerInfo = val;
        },
    },
    actions: {
        setLangTypeValAc(context, val) {
            context.commit("setLangTypeVal", val);
        },
        setSmsSwitchAc(context, val) {
            context.commit("setSmsSwitch", val);
        },
        setPayPwdAc(context, val) {
            context.commit("setPayPwd", val);
        },
        setTransferAc(context, val) {
            context.commit("setTransfer", val);
        },
        setWithdrawalAc(context, val) {
            context.commit("setWithdrawal", val);
        },
        setDepositAc(context, val) {
            context.commit("setDeposit", val);
        },
        setShrinkAc(context, val) {
            context.commit("setShrink", val);
        },
        setIsLoginShowAc(context, val) {
            context.commit("setIsLoginShow", val);
        },
        setUserInfoAc(context, val) {
            context.commit("setUserInfo", val);
        },
        setHasLoginAc(context, val) {
            context.commit("setHasLogin", val);
        },
        setNavsListAc(context, val) {
            context.commit("setNavsList", val);
        },
        setNavsValAc(context, val) {
            context.commit("setNavsVal", val);
        },
        setMessageAc(context, val) {
            context.commit("setMessage", val);
        },
        setAccountMoneyAc(context, val) {
            context.commit("setAccountMoney", val);
        },
        setBaseInfoAC(context, val) {
            context.commit("setBaseInfo", val);
        },
        setSmsAc(context, val) {
            context.commit("setSms", val);
        },
        setIsBankLengthAc(context, val) {
            context.commit("setIsBankLength", val);
        },
        setRegAuthAc(context, val) {
            context.commit("setRegAuth", val);
        },
        setHotsGameListAc(context, val) {
            context.commit("setHotsGameList", val);
        },
        setRegisterInfoAc(context, val) {
            context.commit("setRegisterInfo", val);
        }
    },
    modules: {},
});