<template>
  <div class="loading" v-if="visible">
    <div class="loading-box">
      <div class="laoding-flash icon-loading"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // background: #16181f;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-box {
    background: #15191e;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.laoding-flash {
  position: relative;
  display: block;
  font-size: 0.24rem;
  animation: load infinite 1s linear;
}
@keyframes load {
  0% {
    transform: rotate();
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
