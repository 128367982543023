<template>
  <div class="popup" ref="canlcePop">
    <div class="popup-main small-title">
      <div class="popup-title">
        <span class="title">{{ $t('components.cancelOrder.530861-0') }}</span>
        <span class="close" @click="close"></span>
      </div>
      <div class="popImg-main">
        <p class="small-yy">
          {{ $t('components.cancelOrder.530861-1') }}
        </p>
        <p class="small-yy">{{ $t('components.cancelOrder.530861-2') }}</p>
        <ul>
          <li
            v-for="(item, index) in cancelList"
            :key="index"
            :class="{ 'hover-target': cancelIndex === item.type }"
            @click="selectCancel(item, index)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="popImg-link">
          <el-button type="primary" @click="cancelOk">{{ $t('components.cancelOrder.530861-3') }}</el-button>
          <el-button @click="close">{{ $t('components.cancelOrder.530861-4') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    canlceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cancelList: [
        { name: this.$t('components.cancelOrder.530861-5'), type: 1 },
        { name: this.$t('components.cancelOrder.530861-6'), type: 2 },
        { name: this.$t('components.cancelOrder.530861-7'), type: 3 },
        { name: this.$t('components.cancelOrder.530861-8'), type: 4 },
        { name: this.$t('components.cancelOrder.530861-9'), type: 5 },
      ],
      cancelIndex: undefined,
    };
  },
  mounted() {
    this.initWeb();
  },
  methods: {
    initWeb() {
      const dom = this.$refs.canlcePop;
      document.body.appendChild(dom);
    },
    close() {
      this.$emit("update:visible");
    },
    selectCancel(val) {
      this.cancelIndex = val.type;
    },
    cancelOk() {
      if (!this.cancelIndex) {
        this.$message({
          type: "warning",
          message: this.$t('components.cancelOrder.530861-10'),
        });
        return;
      }
      this.close();
      this.$Api.cancelRechargeOrder(this.canlceId).then((res) => {
        if (res) {
          this.$message({
            type: "success",
            message: this.$t('components.cancelOrder.530861-11'),
          });
          this.$store.dispatch("setDepositAc", false);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 110;

  &-main {
    width: 700px;
    border-radius: 4px;
    background: #24262b;
    padding-bottom: 24px;
  }

  &-title {
    text-align: center;
    position: relative;
    font-size: 24px;
    background: #17191b;

    .title {
      height: 60px;
      line-height: 60px;
    }

    .close {
      position: absolute;
      right: 15px;
      top: 50%;
      width: 24px;
      height: 24px;
      // background: url("@/assets/images/userinfo/close.png") no-repeat center;
      background-size: 80%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.popup-zw {
  padding: 24px;

  .small {
    font-size: 12px;
  }

  .list {
    margin-top: 10px;
    color: #2a2b2c;

    span {
      color: #e95b5b;
    }

    .link {
      padding: 2px 8px;
      display: inline-block;
      border-radius: 4px;
      border: 1px solid #6aaaf5;
      color: #6aaaf5;
      margin-bottom: 12px;
      margin-top: 8px;
      color: #179cff;
      cursor: pointer;
    }

    &-tips {
      font-size: 12px;
      margin-bottom: 12px;
      margin-top: 8px;
      color: #7981a4;
    }
  }
}

.small-title {
  width: 480px;

  .popup-title {
    padding: 15px 15px 10px;
    background: #17191b;
    font-size: 18px;
    color: white;

    .title {
      line-height: 1 !important;
      height: auto;
    }
  }
}

.popImg-main {
  text-align: left;
  padding: 24px 0;
  width: 60%;
  margin: 0 auto;

  ul {
    margin-top: 8px;

    li {
      position: relative;
      width: 100%;
      height: 40px;
      cursor: pointer;
      border-radius: 8px;
      margin-bottom: 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      &.hover-target {
        border: 1px solid #009d81;
      }
    }
  }

  .text-red {
    padding: 10px 0;
  }
}

.popImg-link {
  display: flex;
  width: 100%;
  margin-top: 32px;

  button {
    flex: 1;
  }
}
</style>
