import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui"
import mixins from '@/mixins/index.js'
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/lang/index.js'
import '@/assets/css/all.scss'
import loadong from './utils/loading'
import api from "@/api/index";
import VueLazyload from "vue-lazyload";


Vue.use(VueLazyload, {
    preload: 1.3,
    loading: require('@/assets/pic/imgPng.png'),
    error: require('@/assets/pic/imgPng.png'),
    attempt: 3,
})
Vue.mixin(mixins)
Vue.config.productionTip = false;
Vue.use(ElementUI)
Vue.use(loadong)
Vue.prototype.$Api = api;

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
}).$mount("#app");