<template>
  <div class="jf-search">
    <div class="jf-search-scroll">
      <div class="jf-search-box">
        <div class="jf-search-type" v-if="searchClass">
          <el-select
            v-model="platType"
            :placeholder="$t('components.SearchGame.672196-0')"
            class="select"
          >
            <el-option
              v-for="item in searchClass"
              :key="item.gameType"
              :label="item.name"
              :value="item.gameType"
            >
            </el-option>
          </el-select>
        </div>
        <span class="icon-search"></span>
        <input
          type="text"
          class="input"
          v-model="gameName"
          :placeholder="$t('components.SearchGame.672196-1')"
          @blur="starSearch"
        />
        <span class="canlce" @click="closeSearch">{{
          $t("components.SearchGame.672196-2")
        }}</span>
      </div>
      <div class="jf-search-sec">
        {{ $t("components.SearchGame.672196-3") }}
      </div>
      <div class="jf-search-tj" v-if="searchList && searchList.length">
        <div class="title">
          {{ $t("components.SearchGame.672196-4") }}{{ gameName
          }}{{ $t("components.SearchGame.672196-5") }}{{ searchList.length
          }}{{ $t("components.SearchGame.672196-6") }}
        </div>
        <div class="list">
          <div
            class="row"
            v-for="(item, index) in searchList"
            :key="index"
            @click="openGame(item)"
          >
            <span class="name">{{ item.gameName }}</span>
            <img v-lazy="item.gameImgUrl" />
          </div>
        </div>
      </div>
      <div class="jf-search-tj" v-if="hostlist && hostlist.length">
        <div class="title">{{ $t("components.SearchGame.672196-7") }}</div>
        <div class="list">
          <div class="row" v-for="(item, index) in hostlist" :key="index">
            <img v-lazy="item.gameImgUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hostlist: null,
      platType: "",
      gameName: "",
      searchList: null,
      searchClass: null,
    };
  },
  computed: {
    ...mapState(["navsList"]),
  },
  watch: {
    navsList(val) {
      if (val) {
        this.initInfo();
      }
    },
    langTypeVal(now, old) {
      if (now !== old) {
        this.getGameListByGameType();
      }
    },
  },
  mounted() {
    this.initInfo();
  },
  methods: {
    closeSearch() {
      this.$emit("update:visible", false);
    },
    starSearch(e) {
      if (e.target.value.length > 1) {
        this.getGameListByGameType();
      } else {
        this.$message({
          message: this.$t("components.SearchGame.672196-8"),
          type: "warning",
        });
      }
    },
    // 打开游戏
    openGame(val) {
      if (!this.isLogin) {
        this.openLoginPop();
        return;
      }
      this.$loading.show();
      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          this.$loading.hide();
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
    initInfo() {
      if (this.navsList) {
        let arr = [];
        this.navsList.forEach((item) => {
          if (["2", "6"].includes(item.gameType)) {
            arr.push(item);
          }
        });
        this.searchClass = arr;
        this.platType = this.searchClass[0].gameType;
      }
    },
    getGameListByGameType() {
      const params = {
        gameName: this.gameName,
        gameType: this.platType,
        pageIndex: 1,
        pageSize: 50,
        platType: "",
        languageType: this.userInfo
          ? this.userInfo.languageType
          : localStorage.getItem("langParam") || "",
      };
      this.$loading.show();
      this.$Api.getGameListByGameType(params).then((res) => {
        if (res) {
          this.$loading.hide();
          this.searchList = res.page.records;
          //   console.log(res);
        }
      });
    },
    getTop5PopularSearchGame() {
      const params = {
        platType: "",
        gameType: this.platType,
      };
      this.$Api.getTop5PopularSearchGame(params).then((res) => {
        this.hostlist = res.popularSearch;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.jf-search {
  position: absolute;
  top: 0.64rem;
  height: 100%;
  //   bottom: 0;
  width: 14.2rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  &-scroll {
    background: #24262b;
    padding: 0.2rem 0.2rem 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  &-box {
    width: 100%;
    position: relative;
    height: 0.48rem;
    background: #191919;
    border-radius: 0.04rem;
    color: #6e6e6e;
    font-size: 0.16rem;
    text-align: center;
    display: flex;
    z-index: 1;
    margin: 0.1rem auto 0;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    gap: 0.1rem;
    .input {
      flex: 1;
      background: white;
      font-size: 0.14rem;
      background: none;
      border: none;
      box-shadow: none;
      outline: none;
      color: white;
      font-size: 0.15rem;
    }
    .canlce {
      cursor: pointer;
      font-size: 0.13rem;
      color: white;
    }
  }
  &-type {
    padding-right: 0.04rem;
    margin-right: 0.1rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      height: 0.26rem;
      width: 1px;
      background: #333;
      transform: translateY(-50%);
    }
  }
}
.jf-search-sec {
  font-size: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #98a7b5;
  width: 100%;
  font-size: 0.16rem;
}
/deep/.select {
  width: 90px;
  padding: 0;
  .el-input__inner {
    border: none !important;
    padding: 0;
  }
  .el-input__inner:hover,
  .el-input__inner:focus {
    border: none !important;
  }
}

.jf-search-tj {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  .title {
    font-size: 0.18rem;
    color: white;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(8, minmax(10.2em, 1fr));
    gap: 0.15rem 0.15rem;
    .row {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 0.04rem;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .name {
      position: absolute;
      bottom: 6px;
      left: 6px;
      right: 6px;
      border-radius: 10px;
      font-size: 12px;
      color: white;
      background: rgba(0, 0, 0, 0.5);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 4px 0;
    }
  }
}
</style>
