<template>
  <div class="user-box" v-if="bankList">
    <div class="user-box-title">
      {{ info.name }}
      <!-- ({{ bankList.length + "/" + info.number }}) -->
    </div>
    <div class="add-bank-list">
      <div
        class="add-bank-row bank-bg"
        v-for="(item, index) in bankList"
        @click="tabBank(item, index)"
        :class="[{ hover: index === bankIndex }, item.class]"
        :key="index"
      >
        <div class="gou"></div>
        <div class="bank-name">
          <span class="icon" v-if="item.iconUrl">
            <img :src="item.iconUrl" alt=""
          /></span>
          <div class="info">
            <span class="info-name">{{
              item.bankName || item.name || item.accountName
            }}</span>
            <p class="info-type">
              {{ item.cardType }}
            </p>
          </div>
        </div>
        <div class="bank-number">
          {{ item.bankcardNo || item.address || item.account }}
        </div>
      </div>
      <div class="add-bank-row" @click="addBankWeb">
        <span class="name">
          <!-- <img src="@/assets/images/mine/icon63.png" /> -->
          {{ $t("withdrawal.bank.476080-0") + info.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bankList: null,
      bankIndex: 0,
    };
  },
  watch: {
    info(val) {
      if (val.withdrawMethodType) {
        this.initPost();
      }
    },
  },
  mounted() {
    this.initPost();
  },
  methods: {
    // 初始化
    initPost() {
      switch (this.info.id) {
        case 1:
          this.queryUserBankCardList();
          break;
        case 2:
          this.queryUserBlockchainList();
          break;
        default:
          this.queryThirdPartAccountList(this.info.id);
      }
    },
    // 切换
    tabBank(val, index) {
      this.bankIndex = index;
      this.postParams(val);
    },
    //添加银行卡
    addBankWeb() {
      this.$store.dispatch("setWithdrawalAc", false);
      this.$router.push("/userInfo/bank");
    },
    postParams(val) {
      this.$emit("update", val);
    },
    // 银行卡
    queryUserBankCardList() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          res.userBankCardList.forEach((item) => {
            item.cardType =
              item.cardType === 1
                ? this.$t("withdrawal.bank.476080-1")
                : this.$t("withdrawal.bank.476080-2");
          });
          this.bankList = res.userBankCardList;
          this.bankIndex = 0;
          this.postParams(this.bankList[this.bankIndex]);
        }
      });
    },
    // 区块链
    queryUserBlockchainList() {
      this.$Api.queryUserBlockchainList().then((res) => {
        if (res) {
          res.userBlockchainList.forEach((item) => {
            item.iconUrl = "";
            item.cardType = item.networkType;
            item.class = "usdt";
          });
          this.bankIndex = 0;
          this.bankList = res.userBlockchainList;
          this.postParams(this.bankList[this.bankIndex]);
        }
      });
    },
    // 第三方
    queryThirdPartAccountList(val) {
      const type = val === 3 ? "EBpay" : "KOIpay";
      this.$Api
        .queryThirdPartAccountList({ withdrawMethodId: val })
        .then((res) => {
          if (res) {
            res.userThirdPartAccountList.forEach((item) => {
              item.iconUrl = "";
              item.cardType = [3, 4].includes(val) ? type : "";
              item.class = val === 3 ? "ebpay" : "koipay";
            });
            this.bankIndex = 0;
            this.bankList = res.userThirdPartAccountList;
            this.postParams(this.bankList[this.bankIndex]);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-bank-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.1rem;
}

.add-bank-row {
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  height: 98px;
  box-shadow: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #17181b;
  border: 1px dashed #3a3b46;

  .name {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .sec {
    font-size: 12px;
    margin-top: 4px;
  }

  &.usdt {
    background: linear-gradient(180deg, #58f1c6, #47c19f);
  }

  &.ebpay {
    background: linear-gradient(0deg, #548de0, #92b8ee);
  }

  &.koipay {
    background: linear-gradient(314.81deg, #32bdbc 3.14%, #86e1cd 97%);
  }
}

.bank-bg {
  background-image: linear-gradient(315deg, #d57074, #f7acaf);
  box-shadow: none;
  color: #788b9d;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative;
  border: 1px solid transparent;
  .gou {
    position: absolute;
    top: 6px;
    right: 6px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    width: 18px;
    height: 18px;
    opacity: 0.3;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 7px;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      border-radius: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-45%, -54%) rotate(45deg);
    }
  }

  .bank-name {
    display: flex;
    text-align: left;
    width: 100%;
    flex: 1;

    .icon {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80%;
        border-radius: 100%;
        overflow: hidden;
      }
    }

    .info {
      color: white;

      &-name {
        font-size: 14px;
      }

      &-type {
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }

  .bank-number {
    color: white;
    text-align: left;
    width: 100%;
  }

  .name {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .sec {
    font-size: 12px;
    margin-top: 4px;
  }

  &.hover {
    .gou {
      opacity: 1;
      background: rgba(255, 255, 255, 0.8);

      &::before {
        border-right: 2px solid #409eff;
        border-bottom: 2px solid #409eff;
      }
    }
  }
}
.user-box-title {
  color: white;
  font-size: 14px;
  font-weight: 700;
}
</style>
