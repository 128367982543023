import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/";
import UserInfoLayout from "../views/userInfo/layout.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/promotion",
        name: "promotion",
        component: () =>
            import ("../views/activity/promotion.vue"),
    },
    {
        path: "/promotion/detail",
        name: "detail",
        component: () =>
            import ("../views/activity/detail.vue"),
    },
    {
        path: "/download",
        name: "download",
        component: () =>
            import ("../views/download.vue"),
    },
    {
        path: "/terms",
        name: "terms",
        component: () =>
            import ("../views/help/terms.vue"),
    },
    {
        path: "/preventLaunderMoney",
        name: "preventLaunderMoney",
        component: () =>
            import ("../views/help/preventLaunderMoney.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () =>
            import ("../views/help/privacy.vue"),
    },
    {
        path: "/exclusionPolicy",
        name: "exclusionPolicy",
        component: () =>
            import ("../views/help/exclusionPolicy.vue"),
    },
    {
        path: "/responsible",
        name: "responsible",
        component: () =>
            import ("../views/help/responsible.vue"),
    },
    {
        path: "/help",
        name: "help",
        component: () =>
            import ("../views/help/help.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import ("../views/help/about.vue"),
    },
    {
        path: "/howtoPlay",
        name: "howtoPlay",
        component: () =>
            import ("../views/help/howtoPlay.vue"),
    },
    {
        path: "/terms-sports",
        name: "terms-sports",
        component: () =>
            import ("../views/help/terms-sports.vue"),
    },
    {
        path: "/gamingPolicy",
        name: "gamingPolicy",
        component: () =>
            import ("../views/help/gamingPolicy.vue"),
    },
    {
        path: "/game",
        name: "game",
        component: () =>
            import ("../views/game/index.vue"),
    },
    {
        path: "/invite",
        name: "invite",
        component: () =>
            import ("../views/invite/index.vue"),
    },
    {
        path: "/userInfo",
        component: UserInfoLayout,
        redirect: "/userInfo/index",
        children: [{
            path: "/userInfo/index",
            name: "userInfo",
            meta: {
                routeMap: 4,
            },
            component: () =>
                import ("../views/userInfo/index.vue"),
        }, {
            path: "/userInfo/transaction",
            name: "transaction",
            meta: {
                routeMap: 5,
            },
            component: () =>
                import ("../views/userInfo/transaction.vue"),
        }, {
            path: "/userInfo/bet",
            name: "bet",
            meta: {
                routeMap: 6,
            },
            component: () =>
                import ("../views/userInfo/bet.vue"),
        }, {
            path: "/userInfo/welfare",
            name: "welfare",
            meta: {
                routeMap: 7,
            },
            component: () =>
                import ("../views/userInfo/welfare.vue"),
        }, {
            path: "/userInfo/message",
            name: "message",
            meta: {
                routeMap: 8,
            },
            component: () =>
                import ("../views/userInfo/message.vue"),
        }, {
            path: "/userInfo/feedback",
            name: "feedback",
            meta: {
                routeMap: 9,
            },
            component: () =>
                import ("../views/userInfo/feedback.vue"),
        }, {
            path: "/userInfo/feedbackList",
            name: "feedbackList",
            meta: {
                routeMap: 9,
            },
            component: () =>
                import ("../views/userInfo/feedback/list.vue"),
        }, {
            path: "/userInfo/feedbackDetail",
            name: "feedbackDetail",
            meta: {
                routeMap: 9,
            },
            component: () =>
                import ("../views/userInfo/feedback/detail.vue"),
        }, {
            path: "/userInfo/bank",
            name: "bank",
            meta: {
                routeMap: 10,
            },
            component: () =>
                import ("../views/userInfo/bank.vue"),
        }]
    },

    {
        path: '/user/update',
        name: 'update',
        component: () =>
            import ('../views/vip/update.vue')
    },
    {
        path: '/user/vip',
        name: 'introduce',
        component: () =>
            import ('../views/vip/index.vue')
    },
    {
        path: '/vip/detail',
        name: 'detail',
        component: () =>
            import ('../views/vip/detail.vue')
    },
    {
        path: '/vip/exclusive',
        name: 'exclusive',
        component: () =>
            import ('../views/vip/exclusive.vue')
    },
    {
        path: '/vip/secretary',
        name: 'secretary',
        component: () =>
            import ('../views/vip/secretary.vue')
    },
    {
        path: '/vip/journey',
        name: 'journey',
        component: () =>
            import ('../views/vip/journey.vue')
    },
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

export default router;