<template>
  <div class="jf-dep-pop">
    <div class="jf-dep-main">
      <div class="jf-dep-title">
        <div class="left">{{ $t("deposit.index.515008-0") }}</div>
        <div class="right icon-close" @click="close"></div>
      </div>
      <div class="jf-dep-content">
        <Mode v-if="step === 1" @update="submitOrder" />
        <Order v-if="step === 2" :info="parms" :step="step" />
        <Three v-if="step === 3" :info="parms" />
      </div>
    </div>
  </div>
</template>
<script>
import Mode from "./components/mode.vue";
import Order from "./components/order.vue";
import Three from "./components/three.vue";
export default {
  components: {
    Mode,
    Order,
    Three,
  },
  data() {
    return {
      step: 1,
      parms: null,
    };
  },
  methods: {
    close() {
      this.$store.dispatch("setDepositAc", false);
    },
    submitOrder(val, e) {
      console.log(val, e);
      this.parms = val;
      this.step = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.jf-dep-pop {
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jf-dep-main {
  margin: 0 auto;
  max-width: 9.4rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #24262b;
}
.jf-dep-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0.44rem;
  padding: 0.1rem 0.14rem;
  background: #17191b;
  .left {
    font-size: 0.16rem;
    color: white;
  }
  .right {
    cursor: pointer;
  }
}
.jf-dep-content {
  flex: 1;
  overflow-y: auto;
  padding: 0.2rem;
  max-height: 8.2rem;
  width: 5.8rem;
  position: relative;
}
</style>
