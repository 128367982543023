<template>
  <section class="footer jf-wid-14">
    <div class="menus">
      <dl v-for="(item, index) in menus" :key="index">
        <dt>{{ item.name }}</dt>
        <template v-if="item.list">
          <dd
            v-for="(row, rowIndex) in item.list"
            :key="rowIndex"
            @click="jumpWeb(row)"
          >
            {{ row.name }}
          </dd>
        </template>
        <div class="live-list" v-if="item.links">
          <a
            href="javascript:void(0);"
            v-for="(ite, iteIndex) in item.links"
            @click="jumpWeb(ite)"
            :key="iteIndex"
          >
            <img :src="ite.img" />
          </a>
        </div>
      </dl>
    </div>
    <div class="copy">
      <div class="left">
        <img src="@/assets/pic/logo.webp" class="logo" />
        <div class="text">
          {{ $t("components.Footer.922320-0") }}
          Zuikertuintjeweg Z/N（Zuikertuin
          {{ $t("components.Footer.922320-1") }}
          {{ $t("components.Footer.922320-2")
          }}<a
            href="https://licensing.gaming-curacao.com/validator/?lh=5b2b20bb245e247d7f75bcbca978b8bc&rlh=bab156701a10a7c2c26672c49a5c0dec"
            target="_black"
            >GLH-OCCHKTW0705152022</a
          >
        </div>
      </div>
      <div class="right">
        <div class="flex-top">
          <div class="year">18+</div>
          <div class="main">
            {{ $t("components.Footer.922320-3") }}
            <br />{{ $t("components.Footer.922320-4") }}<br />
            {{ $t("components.Footer.922320-5") }}
          </div>
        </div>
        <div class="copy-text">{{ $t("components.Footer.922320-6") }}</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          name: this.$t("components.Footer.922320-7"),
          list: [
            {
              name: this.$t("components.Footer.922320-8"),
              link: "/terms",
            },
            {
              name: this.$t("components.Footer.922320-9"),
              link: "/preventLaunderMoney",
            },
            {
              name: this.$t("components.Footer.922320-10"),
              link: "/privacy",
            },
            {
              name: this.$t("components.Footer.922320-11"),
              link: "/exclusionPolicy",
            },
            {
              name: this.$t("components.Footer.922320-12"),
              link: "/responsible",
            },
          ],
        },
        {
          name: this.$t("components.Footer.922320-13"),
          list: [
            {
              name: this.$t("components.Footer.922320-14"),
              link: "/help",
            },
            {
              name: this.$t("components.Footer.922320-15"),
              link: "/about",
            },
            {
              name: this.$t("components.Footer.922320-16"),
              link: "/howtoPlay",
            },
            {
              name: this.$t("components.Footer.922320-17"),
              link: "/terms-sports",
            },
            {
              name: this.$t("components.Footer.922320-18"),
              link: "/gamingPolicy",
            },
          ],
        },
        {
          name: this.$t("components.Footer.922320-19"),
          links: [
            {
              img: require("@/assets/pic/live06.webp"),
              link: "",
              type: 6,
            },
            {
              img: require("@/assets/pic/live07.webp"),
              link: "",
              type: 13,
            },
          ],
        },
        {
          name: this.$t("components.Footer.922320-20"),
          links: [
            {
              img: require("@/assets/pic/live02.webp"),
              link: "",
              type: 11,
            },
            {
              img: require("@/assets/pic/live05.webp"),
              link: "",
              type: 12,
            },
            {
              img: require("@/assets/pic/live04.webp"),
              link: "",
              type: 13,
            },
            {
              img: require("@/assets/pic/live03.webp"),
              link: "",
              type: 14,
              mobile: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    jumpWeb(val) {
      if (val.type) {
        if (val.mobile) {
          var phoneLink = "tel:" + this.baseInfo[val.type].configValue;
          var link = document.createElement("a");
          link.href = phoneLink;
          link.click();
          return;
        }
        this.openContact(this.baseInfo[val.type].configValue);
        return;
      }
      this.openUrl(val.link);
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  background: var(--bg-3);
  padding: 0.35rem 0.3rem;
}
.menus {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  dl {
    dt {
      font-size: 0.15rem;
      color: var(--text);
      margin-bottom: 0.2rem;
    }
    dd {
      color: var(--text-1);
      font-size: 0.12rem;
      margin-top: 0.09rem;
      cursor: pointer;
    }
    div {
      width: 1.2rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.04rem;
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
}
.copy {
  display: flex;
  gap: 1rem;
  margin-top: 0.3rem;
  .left,
  .right {
    flex: 1;
  }
  .left {
    .logo {
      height: 0.33rem;
    }
    .text {
      line-height: 1.5;
      color: var(--text-1);
      margin-top: 0.3rem;
      a {
        color: var(--text);
      }
    }
  }
  .right {
    .year {
      border: 2px solid red;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.14rem;
      color: red;
      flex: none;
      margin-right: 0.2rem;
      margin-top: 0.06rem;
    }
    .main {
      font-size: 0.12rem;
      line-height: 1.75;
    }
    .copy-text {
      font-size: 0.14rem;
      margin-top: 0.27rem;
    }
  }
}
</style>
